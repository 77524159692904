<template>
      <div class="section home-top">
      
        <div class="content">
          <h1 class="title">
            Next Generation<br />
            <span class="strong">Eyewear Platform</span>
          </h1>
          <div class="buttons">
            <div class="button-white" v-if="language=='fr'" @click="$router.push({ name: 'collection-glass', params: { id: 29 } })">Personnaliser</div>
            <div class="button-white" v-else @click="$router.push({ name: 'collection-glass', params: { id: 29 } })">Customize</div>
            <div class="arrows-right">
              <div class="arrow-grey"><img src="../../../../public/assets/images/arrow_left_w.svg" /></div>
              <div class="arrow-grey"><img src="../../../../public/assets/images/arrow_right_w.svg" /></div>
            </div>
          </div>

          <div class="product-home">
            <div class="image">
              <img src="../../../../public/assets/images/model1.png"  />
            </div>
            <div class="product-infos">
              <div>
                Tête en l’air<br />
                <span class="grey">Rêveur Lucide</span>

              </div>
              <div><span class="grey">/</span></div>
              <div>
                590€<br />
                <span class="grey">2 coloris<br />
                Sur mesure disponible</span>
              </div>
            </div>
          </div>
         
          <!-- <div class="button-white arrow" @click="$router.push({ name: 'collection', params: { toggle: 'solaire' } })"><img src="../../../../public/assets/images/down.svg" height="24" /></div> -->
        </div>
       
    </div>
</template>

<script>
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider"

export default {
    name: 'HomeTopComponent2',
    props:["language"],
    data(){
        return {
          slider_logo:null,
          collab1SliderOptions: {
               container: '.slider-logo',
               mouseDrag: false,
               navigation:false,
               navPosition:'bottom',
               autoplay:true,
               loop: true,
               slideBy:5,
               items: 3,
               center:false,
               gutter: 0,
               swipeAngle: 45,
               nav:false,
               controls:false,
             
               startIndex:0,
               responsive: {
                1024: {
                       items: 5,
                       gutter:0
               },

                
               760: {
                       items: 4,
                       gutter:0
               }
               
               },
           },
        }
    },
    methods: {
        
    },
    mounted() {
   
    }

}
</script>

<style scoped>

@media screen and (max-width:760px) {

  .bandeau-logos
  {
    padding-top:30px !important;
    padding-bottom:30px !important;
  }
 
  .button-white 
  {
    font-size:15px !important;
  }
}
.bandeau-logos .logos {
  display:flex;
  overflow: hidden;
  align-items: center;
}
.txt {
  display:flex;
  white-space: nowrap;
  /* animation:Scroll 10s linear infinite; */
}
@keyframes Scroll {
  0% {
    transform: translateX(-100%);
  }
}
.buttons .arrows-right
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:110px;
}
.arrow-grey img 
{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.arrow-grey
{
  background-color:rgba(255,255,255,0.1);
  -webkit-backdrop-filter: blur(45px);
  backdrop-filter: blur(45px);
  border-radius: 5px;
  width:42px;
  height:42px;
  position: relative;
  margin-left:12px;
}
.buttons {
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.button-white
{
  background-color: #63FF2B;
  padding:11px 14px;
  border-radius: 5px;
  color:#000;
  flex:1;
  cursor: pointer;
}
.bandeau-logos .logo img 
{
  width:50%;
  margin-left: auto;
  position:relative;
  top:50%;
  transform: translateY(-50%);
  margin-right:auto;
}
.bandeau-logos .logo {
  width:400px;
  display:flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.bandeau-logos {
 

  background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  padding-top:60px;
  padding-bottom:60px;
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
}

  .home-top.section {
    min-height:100vh;
    display:flex;
    position:relative;
  }
  .home-top.section .content h1 .strong
  {
    font-weight: 400;
    color:#999999;
  }
  .home-top.section .content h1 {
    font-family: 'degular';
    color:#fff;
    font-size:52px;

    line-height:1;
    text-align: left;
    font-weight: normal;
    word-break: keep-all;
  }
  .product-home .image img 
  {
    width:100%;
    height:auto;
  }
  .product-home .product-infos .grey 
  {
    color:#999999;
  }
  .product-home .product-infos
  {
    color:#fff;
    margin-top:10px;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    font-size:16px;
    line-height: 1.1;
  }
  .product-home .image
  {
    backdrop-filter: blur(45px);
    -webkit-backdrop-filter: blur(45px);
    background-color:rgba(255,255,255,.5);
    margin-top:20px;
    padding:15% 5%;
  }
  .home-top.section .content {
    position:absolute;
    right:5%;
    top:50%;
    transform: translateY(-50%);
    text-align: left;
    width:26%;
    max-width: 400px;
   
  }
  .home-top.section {
    
    background-image:url(../../../../public/assets/images/home.jpg);
    background-size:cover;
    background-position: center center;
  }
  .home-top.section .right {
    width:40%;
    background:#000;
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  

  .button-black {
    color:#fff;
    margin-top:10px;
    background:#000;
    display:inline-block;
    padding:5px 15px;
    font-size:12px;
    border-radius:40px;
  }
  .button-black span {
    position:relative;
    top:-5px;
    margin-left:10px;
  }
  .button-black img {
    display:inline-block;
  }
  .button-white.arrow 
  {
    margin-left:10px;
    width:51px;
    height:51px;
    display:inline-block;
    padding:0;
    vertical-align: top;
  }
  .button-white.arrow img {
    height:22px;
    position:relative;
    filter: brightness(0) invert(1);
    top:13px;
    transform: rotate(-90deg);
  }
  .button-white img {
    display:inline-block;
  }
 
  .button-white span {

    position:relative;
    top:-5px;
    margin-left:10px;
  }


</style>