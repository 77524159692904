<template>
     <Overlay :like="true" :language="language" v-if="visible" :title="titleModal" :visible="visible" @remove="visible=false"/>

      <div class="section collection">
        <div class="collection-right-module" v-if="loaded">
            <div>
                <div class="seeall" v-if="language=='fr'">
                    <router-link v-if="selectedFilter=='solar'" to="/collections/solar">VOIR TOUT <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else-if="selectedFilter=='optic'" to="/collections/optic">VOIR TOUT <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else to="/collections">VOIR TOUT <span class="num">({{glasses.length}})</span></router-link>
                </div>
                <div class="seeall" v-else>
                    <router-link v-if="selectedFilter=='solar'" to="/collections/solar">SEE ALL <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else-if="selectedFilter=='optic'" to="/collections/optic">SEE ALL <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else to="/collections/solar">SEE ALL <span class="num">({{glasses.length}})</span></router-link>
                </div>
            </div>
            <!-- <div class="contenudesk">
                <img src="../../../../public/assets/images/arrow5.png" @click="swipeToLeft" class="arrow-big arrow-left" /> <img src="../../../../public/assets/images/arrow5.png" @click="swipeToRight" class="arrow-big arrow-right" />
            </div> -->
            
        </div>
        <div class="collection-right-module contenumob" v-if="loaded">
            <div>
                <div class="seeall" v-if="language=='fr'">
                    <router-link v-if="selectedFilter=='solar'" to="/collections/solar">VOIR TOUT <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else-if="selectedFilter=='optic'" to="/collections/optic">VOIR TOUT <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else to="/collections">VOIR TOUT <span class="num">({{glasses.length}})</span></router-link>
                </div>
                <div class="seeall" v-else>
                    <router-link v-if="selectedFilter=='solar'" to="/collections/solar">SEE ALL <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else-if="selectedFilter=='optic'" to="/collections/optic">SEE ALL <span class="num">({{glasses.length}})</span></router-link>
                    <router-link v-else to="/collections/solar">SEE ALL <span class="num">({{glasses.length}})</span></router-link>
                </div>
            </div>
            <!-- <div>
                <img src="../../../../public/assets/images/arrow5.png" @click="swipeToLeft" class="arrow-big arrow-left" /> <img src="../../../../public/assets/images/arrow5.png" @click="swipeToRight" class="arrow-big arrow-right" />
            </div> -->
        </div>
        
      <h2 v-if="language=='fr'">COLLABORATIONS</h2>
      <h2 v-else>COLLABORATIONS</h2>


        <div class="collection-filter home-filter" :class="{one:filterNumber==1,two:filterNumber==2,three:filterNumber==3,four:filterNumber==4,five:filterNumber==5}">
            <div class="collection-filter-border"></div>
            <template v-for="filter in filters" :key="filter.txt" >
                <template v-if="filter.link=='optic' && displayOptic==true">
                    <div class="item optic" :class="{active:selectedFilter==filter.link}" @click="filterCollection(filter.link)" ><span v-if="language=='fr'">{{ filter.txt }}</span><span v-else>{{ filter.txt_en }}</span></div>
                </template>
                <template v-else-if="filter.link=='bluelight' && displayBluelight==true">
                    <div class="item bluelight" :class="{active:selectedFilter==filter.link}" @click="filterCollection(filter.link)" ><span v-if="language=='fr'">{{ filter.txt }}</span><span v-else>{{ filter.txt_en }}</span></div>
                </template>
                <template v-else-if="filter.link=='gaming' && displayGaming==true">
                    <div class="item gaming" :class="{active:selectedFilter==filter.link}" @click="filterCollection(filter.link)" ><span v-if="language=='fr'">{{ filter.txt }}</span><span v-else>{{ filter.txt_en }}</span></div>
                </template>
                <template v-else-if="filter.link=='collaborations'">
                    <div class="item collab" :class="{active:selectedFilter==filter.link}" @click="filterCollection(filter.link)" ><span v-if="language=='fr'">{{ filter.txt }}</span><span v-else>{{ filter.txt_en }}</span></div>
                </template>
                <template v-else-if="filter.link!='optic' && filter.link!='gaming'  && filter.link!='bluelight' && filter.link!='collaborations'">
                    <div class="item optic" :class="{active:selectedFilter==filter.link}" @click="filterCollection(filter.link)" ><span v-if="language=='fr'">{{ filter.txt }}</span><span v-else>{{ filter.txt_en }}</span></div>
                </template>
                
            </template>
          <div class="bar" :style="'left:'+barPosition+'%;width:'+barWidth+'%;'"></div>
        </div>

        

      <div class="products">

      
        <div>

            <div id="prev-collection"><img src="../../../../public/assets/images/arrow_scan.svg" width="50" /></div>
            <div id="next-collection"><img src="../../../../public/assets/images/arrow_scan.svg" width="50" /></div>
            <img src="../../../../public/assets/images/gradient.png" class="gradient" draggable="false" />
            <div class="slider-products" >
                
                <CollectionProduct   v-if="loaded"
                :language="language"

                :glasses="glasses"
                :selectedFilter="selectedFilter"
               
                :slider="true"
                :selectedColorFilter="selectedColorFilter"

                @likePhotoEv="likePhoto"
                @updateSliderEv="updateSlider"
            />
              
     

            </div>
          
            </div>
        </div>

    </div>
</template>

<script>

import filterCollections from "../../../data/landing/filter_collection"
import carouselCollection from "../../../data/landing/carousel_collection"
import collabData from '../../../data/landing/collab_data_collec';
import emitter from 'tiny-emitter/instance'
import {APIRequest} from "@simb2s/senseye-sdk"
import { tns } from "tiny-slider"
import Overlay from "../../overlay/OverlayComponent.vue"
import CollectionProduct from '../Collection/CollectionProductComponent.vue';

export default {
    name: "HomeCollection",
    components:{Overlay,CollectionProduct},
    props: {
        barWidth: Number,
        barPosition: Number,
        selectedFilter: String,
        displayBluelight:Boolean,
        displayGaming:Boolean, 
        displayOptic:Boolean,
        filterNumber:Number,
        language:String
    },
    watch: {
    // whenever question changes, this function will run
    selectedFilter(newFilter, oldFilter) {
        this.loadingLanguage=true
       if(newFilter!=oldFilter)
       {

       
       
       }
       },
       language(oldvalue,newval) {
         this.loadingLanguage=true
      },
    },
    data(){
        const {filters} = filterCollections;
        const {data} = carouselCollection;
        let glasses;
      
        return{
            visible:false,
            loadingLanguage:false,
            collectionPhotosLoading:[],
            loadingFilter:false,
            titleModal:'',
            loaded:false,
            slides_collab1:collabData.data,
            slider_collab1:null,
            collab1SliderOptions: {
                container: '.slider-content-collab1',
                mouseDrag: true,
                navigation:false,
                navPosition:'bottom',
                autoplay:false,
                loop: false,
                items: 1.5,
                center:false,
                gutter: 0,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-collab',  // Node reference
                nextButton: '#next-collab',
                startIndex:0,
                responsive: {
                760: {
                        items: 3.4,
                        gutter:0
                }
                },
            },
            selectedColorFilter:'all',
            filters,
            data,
            hideHover:false,
           
            
            glasses,
            
            
        }
    },
    updated() {
      
      if(this.loaded==true && this.loadingLanguage==false)
        {
  
        if(this.slider_products!=null)
        {
            this.slider_products.destroy()
        }  

        
        //  this.slider_products = tns(
        //     this.productSliderOptions
        //     );
        }
    
           
   },
    async mounted() {
        await this.getGlasses();
        // this.slider_products = tns(
        //     this.productSliderOptions
        // );

        emitter.on('reload-glasses-like', async function (likeId) {
            
            if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
            {
                let data={}
                data.user={}
                data.glass={}
                data.glass.id=likeId
                data.user.id=parseInt(localStorage.id_user_senseye_auth)
                await APIRequest.create('likes',data)
            }
            
            
            await this.getGlasses();
            this.showOverlay('Produit ajouté aux favoris !')
        }, this);
        this.loaded=true
        
    },
    methods: {
        updateSlider() {

          
            if(this.slider_products!=null)
        {
            this.slider_products.destroy()
        }  

        
        //  this.slider_products = tns(
        //     this.productSliderOptions
        //     );
        },
        swipeToLeft()
        {
            if(this.slider_products!=null)
            {
                this.slider_products.goTo('first');
            }
        },
        swipeToRight()
        {
            if(this.slider_products!=null)
            {
                this.slider_products.goTo('last');
            }
        },
        displayLogin(saving, id) {
            
            if (saving == true) {
                emitter.emit('display-popup-login', false, false, false, true, id)
            }
            else {
                emitter.emit('display-popup-login', false, false, true)
            }
        },

        collectionPhotoLoaded(index) {
            this.collectionPhotosLoading[index]=false
        },
        showOverlay(title){
            this.visible = true;
            this.titleModal = title;
        },
        hoverColor(color, index) {
            this.hideHover=true
            this.selectedColorFilter=color
            this.collectionPhotosLoading[index]=true
        },
        resetColor(index) {
            this.hideHover=false
            this.selectedColorFilter='all'
            this.collectionPhotosLoading[index]=true
        },
        async likePhoto(liked,like_id,glass_index)
        {
            if(liked==false)
            {
                if(localStorage.id_user_senseye_auth)
                {
                    if(localStorage.id_user_senseye_auth!=null && localStorage.id_user_senseye_auth!='')
                    {
                        let data={}
                        data.user={}
                        data.glass={}
                        data.glass.id=this.glasses[glass_index].id
                        data.user.id=parseInt(localStorage.id_user_senseye_auth)
                        await APIRequest.create('likes',data)
                    }

                    this.glasses[glass_index].liked=true;
                    if(localStorage.id_user_senseye_auth)
                    {
                        this.showOverlay('Produit ajouté aux favoris !')
                    }
                }
                else{
                    this.displayLogin(true,this.glasses[glass_index].id)
                  
                }
                
            }
            else
            {
                if(like_id!=null)
                {
                    await APIRequest.delete('likes',like_id)
                }
                this.glasses[glass_index].liked=false;
            }
        },
        filterCollection(filter)
        {
            this.$emit('filterCollectionFn', filter)
        },
        async getGlasses()  {
            try{
                var response=await APIRequest.list_with_request('glasses',"?collaboration=1")
               
                this.glasses = response[0];
               
      
                
                
                
            }catch(err){
                console.error(err);
            }
        }
    }
}
</script>

<style scoped >



.product-image-space.loading {
    position:relative;
    z-index:2;
    backdrop-filter: blur(30px);
}
.product-image {
width:100%;
height:auto;
}
.product-image.collec {
    position:absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
    
}
.product-image-space {
    width:100%;
    height:auto;
}
.collection-filter.five .collection-filter-border{
    width:100%;
}
.collection-filter.four .collection-filter-border{
    width:74%;
}
.collection-filter.three .collection-filter-border{
    width:100%;
}
.collection-filter.two .collection-filter-border{
    width:42%;
}
.collection-filter.one .collection-filter-border{
    width:16%;
}
.collection-filter-border {
    position:absolute;
    bottom:0;
    left:0;

    border-bottom:0.5px solid #808080;
    width:100%;
}

.product-image-hover {
    position:absolute;
    width:100%;
    height:auto;
    left:0;
    background: #f1f1f1;
    top:0;
    z-index:1;
    transition:all 0.5s;
    opacity:0;
}
.seeall a .num
{
    font-weight: 500;
}
@media screen and (max-width:360px) {
   .collection-right-module.contenumob
   {
      display:none !important;
   }
}

.collection-right-module.contenumob
   {
      display:none;
   }
.seeall a {
    display: inline-block;
    margin-right:20px;
    font-size:20px;
    color:#000;
    border-bottom: 1px solid black;
    text-decoration: none;
}
.collection-right-module {
    display:inline-flex;
    align-items: center;
    position:absolute;
    right:5%;
    top:96px;

}
.arrow-big.arrow-left {
    transform: rotate(180deg);
    margin-right:10px;
}
.arrow-big
{
    cursor: pointer;
    display:inline-block;
    width:60px;
    height:auto;
}
@media screen and (min-width:760px) and (max-width:1024px) {
    .collection-right-module
    {
        top:250px;
    }
}
@media screen and (min-width:760px) {
    .carousel-cell:hover .product-image-hover {
        opacity:1;
    }
    body .product-price {
        font-size:20px !important;
    }
    .product-price .from
    {
        font-size:12px !important;
    }
    .products .product .bottom .product-title .subtitle
    {
        font-size:14px !important;
    }
}

@media screen and (max-width:760px) {
    .collection-right-module .arrow-big
    {
        width:35px;
    }
    .collection-right-module.contenumob {
       
        justify-content: flex-end !important;
        top:120px !important;
        bottom:auto;
        left:auto !important;
        right:20px !important;
        text-align: right;
    }
    .collection-right-module.contenumob .seeall
    { 
        display:none;
    }
    .collection-right-module
    {
        width:100%;
        justify-content: center;
        top:auto;
        z-index: 25;
        bottom:0px;
        left:0;
        right:auto;
        text-align: center;
    }
   
    body .collection-filter.home-filter
    {
        width:165px !important;
    }


.products .gradient 
{
   display: none !important;
}

.section.collection p {
 width:95% !important;
font-size: 18px !important;
line-height: 25px !important;
}
}

.section.collection #prev-collection[aria-disabled=true] {
  opacity:0;
}.section.collection #next-collection[aria-disabled=true] {
  opacity:0;
}
.products {
    position:relative;
}

#prev-collection
{
   
    position:absolute;
    top:50%;
    left:-20px !important;
    z-index:6;
    transform: translateY(-50%);
}
#next-collection
{
  
    position:absolute;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    z-index:6;
}
#prev-collection img
{
    transform:rotate(-180deg) ;
}
#next-collection img
{
    transform:rotate(0deg) !important;
}

.section.collection h2 {
    font-size:48px;
   
    font-weight:400;
    margin-top:100px;
  }

  .section.collection {
    position:relative;
    z-index: 2;
    border-radius:67px;
    background:#fff;
   
    padding-top:0px;
    padding-bottom:100px;
    padding-left:5%;
  }
  .products .gradient {
   width:20%;
   height:100%;
   position:absolute;
   top:0;
   right:0;
   z-index: 4;
   -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.collection-filter .item.solar{
    width:16%;
}
.collection-filter .item.optic{
    width:50%;
}
.collection-filter .item.collab{
    width:26%;
}
.collection-filter .item.bluelight{
    width:26%;
}
.collection-filter .item.gaming{
    width:16%;
}
.collection-filter .item.active {
    font-weight: 600;
}
.collection-filter .item
{
    width:20%;
    text-align: center;
    cursor:pointer;
    display:inline-block;
    
    
} 
.collection-filter .bar
{
    transition:all 0.5s ease-in-out;
    width:20%;
    height:2px ;
    background:#000;
    position:absolute;
    bottom:0;
}
.collection-filter
{
    position:relative;
    font-size:20px;
    text-align: left;
    width:250px;
  
    padding-bottom:10px;
}

div.products {
  margin-top:50px;
  text-align: left;
}
div.products div.product {
    width: 400px;
    min-height: 0px;
    margin-left: 0;
    margin-right: 1%;
    margin-bottom:0;
}
.product .fitted span {
  position:relative;

  margin-left: 5px;
 
}
.collab-name {
    font-size: 13px;
    margin-top: 10px;
    display: block;
}
.product .fitted {
    font-family: "degular", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;

    background: #000;
    padding: 15px 20px;
    margin-top: 20px;
    border-radius: 7px;
    font-weight: 600;
    font-size: 14px;
  white-space:nowrap;
  letter-spacing: 0.0333em;
    line-height: 1.25;
  position:absolute;
  bottom:-60px;
  left:0;
  display: inline-block;
  background:#000;
  color:#fff;
  margin-left:auto;
  text-transform: uppercase;
  margin-right:auto;
}
.product .fitted img {
  display: inline-block;
}
div.products div.product .bottom {

    bottom: 10px;
    left:10px;
    z-index:3;
}

div.products div.product {
    width:23%;
}
.collab-slider .carousel-cell a {
    position: absolute;
    width:100%;
    height:100%;

    z-index:3;
}

.collab-slider #prev-collab[aria-disabled=true],
.collab-slider #prev-collab2[aria-disabled=true] {
  opacity:0;
}
.collab-slider #next-collab[aria-disabled=true],
.collab-slider #next-collab2[aria-disabled=true] {
  opacity:0;
}

#prev-collab2 img,
#prev-collab img
{
    transform:rotate(-180deg) ;
}
#prev-collab,
#prev-collab2
{
    position:absolute;
    top:50%;
    left:10px !important;
    z-index:3;
    transform: translateY(-50%);
}
#next-collab2,
#next-collab
{
    position:absolute;
    top:50%;
    right:10px !important;
    transform: translateY(-50%);
    z-index:3;
}
.top-collection img {
    width:150px;
}
.top-collection {
    position:absolute;
    top:10px;
    z-index:3;
    left:10px;
}
#next-collab2 img,
#next-collab img
{
    transform:rotate(0deg) !important;
}
.products .product .bottom .product-title
{
	display: inline-block;
    font-size:18px;
	text-transform: uppercase;
}
.products .product .bottom .left {
    width:40%;
}
.products .product .bottom .right {
    text-align: right;
    width:60%;
}
.products .product .bottom .colors {
    display:flex;
    justify-content: flex-end;
    align-items: center;
}
.products .product .bottom .colors .color {
    display:flex;
    width:30px;
    height:30px;
    position:relative;
    border-radius:50%;
    margin-right:2px;
}
.products .product .bottom .colors .color.active {
    border:1px solid #000;
}
.products .product .bottom
{
    display:flex;

}
.products .product .bottom .colors .plus {
    margin-right: 20px;
}
.products .product .bottom .colors .plus img {
    width:20px;
}
.products .product .bottom .colors .color .color-bg {
    width:26px;
    height:26px;
    border-radius:50%;
    position:absolute;
    left:1px;
    top:1px;
    border:1px solid #dbdbdb;
}
</style>